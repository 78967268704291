/* eslint-disable no-new */
/* eslint-disable no-undef */
let scrollEndTimeout = null;

const project = {
	init() {
		this.hamburger();
		this.fixedHeader();
		this.heroAfterAnimation();
		this.speakerOverlay();
	},
	hamburger() {
		const switchMenu = document.getElementById( 'switchMenu' );

		if ( ! switchMenu ) {
			return null;
		}
		const headerMenu = document.querySelector( '.header' );
		const textOpen = switchMenu.querySelector( '.screen-reader-text.open' );
		const textClose = switchMenu.querySelector(
			'.screen-reader-text.close'
		);
		const menuWrapper = document.querySelector( '.header__menu-wrapper' );

		let switchOn = false;

		// Outside click
		document.addEventListener( 'click', function ( event ) {
			if ( headerMenu.classList.contains( 'open' ) ) {
				if (
					! menuWrapper.contains( event.target ) &&
					! switchMenu.contains( event.target )
				) {
					switchMenu.classList.remove( 'open' );
					headerMenu.classList.remove( 'open' );
					textOpen.classList.add( 'active' );
					textClose.classList.remove( 'active' );
					document.body.classList.remove( 'prevent-scroll' );
					switchOn = false;
				}
			}
		} );

		switchMenu.addEventListener( 'click', () => {
			if ( ! switchOn ) {
				switchMenu.classList.add( 'open' );
				headerMenu.classList.add( 'open' );
				textOpen.classList.remove( 'active' );
				textClose.classList.add( 'active' );
				document.body.classList.add( 'prevent-scroll' );
				switchOn = true;
			} else {
				switchMenu.classList.remove( 'open' );
				headerMenu.classList.remove( 'open' );
				textOpen.classList.add( 'active' );
				textClose.classList.remove( 'active' );
				document.body.classList.remove( 'prevent-scroll' );
				switchOn = false;
			}
		} );

		window.addEventListener( 'resize', () => {
			if ( switchOn ) {
				switchMenu.classList.remove( 'open' );
				headerMenu.classList.remove( 'open' );
				textOpen.classList.add( 'active' );
				textClose.classList.remove( 'active' );
				switchOn = false;
				document.body.classList.remove( 'prevent-scroll' );
			}
		} );

		return null;
	},
	heroAfterAnimation() {
		const heroCovers = document.querySelectorAll(
			'.wp-block-cover.is-style-hero, .wp-block-cover.is-style-hero-zurich, .wp-block-cover.is-style-hero-bern'
		);
		const scrollPosition = window.scrollY;
		const newXPosition = scrollPosition * 0.5;

		heroCovers.forEach( ( hero ) => {
			hero.style.setProperty( '--bg-position-x', `${ newXPosition }px` );
		} );

		clearTimeout( scrollEndTimeout );

		scrollEndTimeout = setTimeout( () => {}, 100 );

		requestAnimationFrame( project.heroAfterAnimation );
	},
	speakerOverlay() {
		const speakers = document.querySelectorAll( '.speaker-overlay--open' );
		const overlayWrapper = document.querySelector( '.speaker-overlay' );
		const closeButton = overlayWrapper.querySelector(
			'.speaker-overlay--close'
		);

		const loader = overlayWrapper.querySelector(
			'.speaker-overlay__loader-wrapper'
		);

		const overlayContentWrapper = overlayWrapper.querySelector(
			'.speaker-overlay__content'
		);

		speakers.forEach( ( speaker ) => {
			speaker.addEventListener( 'click', ( e ) => {
				e.preventDefault();
				const postId = e.currentTarget.dataset.id;
				const apiUrl = `${ apiBaseUrl.url }wp/v2/speaker/${ postId }`;

				overlayWrapper.classList.add( 'active' );
				document.body.classList.add( 'overlay-active' );
				loader.classList.remove( 'hide' );

				fetch( apiUrl )
					.then( ( response ) => response.json() )
					.then( ( data ) => {
						loader.classList.add( 'hide' );
						overlayContentWrapper.innerHTML = `${ data.content.rendered }`;
					} )
					.catch( ( error ) => {
						/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
						console.error( error );
					} );
			} );
		} );

		closeButton.addEventListener( 'click', () => {
			overlayWrapper.classList.remove( 'active' );
			document.body.classList.remove( 'overlay-active' );
			overlayContentWrapper.innerHTML = '';
		} );
	},
	// eslint-disable-next-line consistent-return
	fixedHeader() {
		const header = document.getElementsByClassName( 'header--main' );
		let scrollObject = {};

		if ( header.length === 0 ) {
			return false;
		}

		header[ 0 ].classList.remove( 'header--show' );

		scrollObject = {
			x: window.pageXOffset,
			y: window.pageYOffset,
		};
		// If you want to check distance
		if ( scrollObject.y > 100 ) {
			header[ 0 ].classList.add( 'header--scroll' );
		} else {
			header[ 0 ].classList.remove( 'header--scroll' );
		}
	},
	// eslint-disable-next-line consistent-return
	unfixedHeader() {
		const header = document.getElementsByClassName( 'header--main' );
		let scrollObject = {};

		if ( header.length === 0 ) {
			return false;
		}

		scrollObject = {
			x: window.pageXOffset,
			y: window.pageYOffset,
		};

		header[ 0 ].classList.add( 'header--show' );

		if ( scrollObject.y < 100 ) {
			header[ 0 ].classList.remove( 'header--scroll' );
		}
	},
	aos() {
		AOS.init();
	},
};

document.addEventListener( 'DOMContentLoaded', () => {
	project.init();
} );

let lastScrollTop = 0;
let st = 0;

window.addEventListener( 'scroll', () => {
	project.heroAfterAnimation();
	st = window.scrollY || document.documentElement.scrollTop;

	if ( Math.abs( st ) < Math.abs( lastScrollTop ) ) {
		project.unfixedHeader();
	} else {
		project.fixedHeader();
	}

	lastScrollTop = st;
} );
